import './style.scss';

import $ from 'dom7/dist/dom7.min';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const mapClassName = 'jason-map';
const activeClass = 'jason-map-active';

/**
 *  @param { Object } options
 *  @param { String } options.openSelector - 点击显示地图的元素选择器
 *  @param { String } options.moveSelector - 跟随者地图一起移动的元素选择器
 **/
function webmap ({ openSelector, moveSelector }) {
  // 不支持移动设置
  if (isMobile) return false;
  
  // 检查参数
  if (!openSelector) {
    throw Error('jason-map 警告：selector 参数为必须');
  }
  
  let timer = null;
  let $mapEl = $('.' + mapClassName);
  let $moveElArr = $(moveSelector);
  // 单位为 ms
  let moveDuration = parseFloat($mapEl
    .css('transition-duration')
    .split(',')[ 0 ]) * 1000;
  
  // 绑定点击激活事件
  $(document).on('click', openSelector, function (e) {
    // 防止跳转
    e.preventDefault();
    
    // 清除之前的定时器
    clearTimeout(timer);
    
    // 设置 过渡时长
    setTransition();
    
    // 执行过渡动画显示 map
    $mapEl.add($moveElArr).addClass(activeClass);
    
    // 等待结束后清除状态
    timer = clearTransition();
    
    // 设置点击关闭事件
    $(document).once('click', function () {
      clearTimeout(timer);
      
      // 设置 transition
      setTransition();
      
      // 关闭
      $mapEl.add($moveElArr).removeClass(activeClass);
      
      // 等待结束后清除 transition
      timer = clearTransition();
    });
  });
  
  // 设置过渡状态
  function setTransition () {
    $moveElArr.css({
      transition: `transform ${ moveDuration }ms`
    });
  }
  
  // 清除过渡状态
  function clearTransition () {
    // 过渡结束后删除 transition
    return setTimeout(() => {
      $moveElArr.css({ transition: '' });
    }, moveDuration);
  }
}

// window attr
if (window) {
  window.jasonMap = webmap;
}

export default webmap;